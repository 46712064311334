<template>
  <editor
    ref="editor"
    :initData="data"
    :holder-id="generateId()"
    @save="$emit('save', $event)"
    @ready="onReady"
    @change="onChange"
    header
    list
    code
    inline-code
    embed
    link
    marker
    table
    delimiter
    image
    paragraph
    :config="config"
  />
</template>

<script>
  import nanoid from 'nanoid'

  import { Editor } from 'vue-editor-js/src'

  import {
    apiUploadMedia,
  } from '@/functions/api'

  import {
    getWebUrl,
  } from '@/functions/media'

  export default {
    name: 'EditorJS',
    components: {
      Editor,
    },
    props: {
      data: Object,
    },
    model: {
      prop: 'data',
      event: 'save',
    },
    data: () => ({
      config: {
        image: {
          field: 'image',
          types: 'image/*',
          uploader: {
            uploadByFile: async (file) => {
              const result = await apiUploadMedia(file, 'file')
              if (result.ok) {
                return {
                  success: true,
                  file: {
                    url: getWebUrl(result.result.url),
                    original_url: result.result.url
                  }
                }
              }
            },
            uploadByUrl: async (url) => {
              const result = await apiUploadMedia(url, 'url')
              if (result.ok) {
                return {
                  success: true,
                  file: {
                    url: getWebUrl(result.result.url),
                    original_url: result.result.url
                  }
                }
              }
            },
          }
        },
      }
    }),
    methods: {
      generateId() {
        return nanoid()
      },

      save(e) {
      },
      onReady(e) {
      },
      onChange(e) {
        this.$refs.editor.save()
      },
    }
  }
</script>

<style lang="stylus">
  .codex-editor__redactor
    padding-bottom 50px!important

  .ce-paragraph[data-placeholder]:empty:not(:focus)::before
    opacity 1 !important

  .ce-toolbox--opened
    background white

  /*.ce-toolbar__actions
    background-color #f3f5f9
    margin-top -10px
    padding-top 10px
    border-bottom-left-radius 5px

    @media (max-width 650px)
      background-color transparent
      margin-top 0
      padding-top 0*/
</style>