<template>
  <div class="rich-keyboard-editor-wrapper" @mousedown="$emit('save')">
    <!--    <div class="rich-keyboard-editor">-->
    <!--      <div class="editor-header">-->
    <!--        <el-input class="editor-input"/>-->
    <!--        <el-button type="primary" @click="$emit('close')">{{$t('rich_keyboard.save')}}</el-button>-->
    <!--      </div>-->
    <!--      <div class="editor-content">-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="editor-wrapper" @mousedown.stop>
      <div class="editor-page">
        <input
          :value="title"
          @input="$emit('update:title', $event.target.value)"
          class="editor-title-input"
          :placeholder="$t('keyboard.placeholders.text')"/>

        <button
          class="button button_type_filled button_size_lg button_theme_success close-btn"
          @click="$emit('close')">
          {{$t('keyboard.done')}}
        </button>

        <editor-js
          :data="initialContent"
          @save="$emit('update:content', $event)"
          class="editor-js"/>
      </div>
    </div>
  </div>
</template>

<script>
  import EditorJs from '@/components/EditorJS.vue'

  export default {
    name: 'RichKeyboardEditor',
    components: {
      EditorJs,
    },
    props: {
      title: String,
      content: Object,
    },
    data() {
      return {
        initialContent: this.content || {}
      }
    },
    methods: {
      handleDocumentKeydown(event) {
        if (event.keyCode === 27) {
          return this.$emit('close')
        }
      },
    },
    mounted() {
      document.addEventListener('keydown', this.handleDocumentKeydown)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.handleDocumentKeydown)
    },
  }
</script>

<style scoped lang="stylus">
  .rich-keyboard-editor-wrapper
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    z-index 100
    height 100vh

    overflow auto

    background #eef5fa

    display flex
    flex-direction column
    justify-content flex-start
    align-items center

  .editor-wrapper
    padding 70px 10px
    width 100%
    max-width 800px
    @media (max-width 650px)
      justify-content flex-start

  .editor-page
    padding 40px
    min-height 300px

    box-shadow 0 24px 24px -18px rgba(69, 104, 129, .33), 0 9px 45px 0 rgba(114, 119, 160, .12)
    border-radius 8px
    background white

    display grid
    grid-template-columns 1fr auto auto
    grid-template-rows auto 1fr
    grid-row-gap 1rem
    grid-column-gap 1rem
    grid-template-areas "title delete save"\
    "editor editor editor"

  .editor-title-input
    grid-area title

    border none
    outline none
    font-size 16px

    width 100%

  .editor-js
    grid-area editor
    margin 0 -20px
    min-height 30vh

  .delete-btn
    grid-area delete

  .close-btn
    grid-area save
</style>
